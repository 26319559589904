<template>
	<div class="flex space-x-4">
		<a
			v-for="link in links"
			:key="link.name"
			target="_blank"
			rel="noopener"
			:href="link.url"
		>
			<img
				:src="link.imgSrc"
				:alt="link.alt"
				width="18"
				height="18"
			>
		</a>
	</div>
</template>

<script defer>
export default {
	props: {
		links: {
			type: Array,
			required: true
		}
	}
}
</script>
